import React from 'react'
import Styled from '@emotion/styled'
import breakpoint from '../utils/breakpoint'

export default () => {
  return (
    <Style className='social-media'>
      <a className='social-media-link' href='https://line.me/ti/p/wF-4oFBhhc'>
        <i class='fab fa-line'></i>
      </a>
      <a className='social-media-link' href='https://www.facebook.com/HICondominium'>
        <i class='fab fa-facebook-f'></i>
      </a>
      <a className='social-media-link' href='https://www.instagram.com/hi_condo_byure'>
        <i class='fab fa-instagram'></i>
      </a>
      <a className='social-media-link' href='https://twitter.com/hi_condo'>
        <i class='fab fa-twitter'></i>
      </a>
    </Style>
  )
}

const Style = Styled.div`
  label: SocailMedia;

  display: flex;
  justify-content: center;
  padding-top: 18px;
  font-size: 11px;
  font-weight: bold;

  .social-label {
    display: inline-block;
  }

  .social-media-link {
    color: #fff;
    display: inline-flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: 2px solid #fff;
    border-radius: 100%;
    font-size: 20px;
    margin: 0 10px;
  }

  ${breakpoint('M')} {
    .social-label {
      display: block;
      margin-bottom: 10px;
    }

    .social-media-link {
      margin: 0 10px;
    }
  }
`
